define("admin/routes/devices/show", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    notify: Ember.inject.service(),
    model({
      device_id
    }) {
      return this.store.findRecord('device', device_id);
    },
    actions: {
      dissociateUser() {
        const device = Ember.get(this, 'controller.model');
        const email = Ember.get(device, 'user.email');
        const confirmed = confirm(`WARNING!\n\nThis will permanently dissociate ${email} from this device.\n\nThey will no longer be able to see it in their dashboard.`);
        if (confirmed) {
          device.set('user', null);
          device.save().then(() => {
            Ember.get(this, 'notify').success('Success! The device was dissociated.');
          }).catch(() => {
            Ember.get(this, 'notify').alert('Something went wrong, and the device could not be dissociated.');
          });
        }
      }
    }
  });
  _exports.default = _default;
});