define("admin/lib/graphs/time-series-counter", ["exports", "moment"], function (_exports, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const randomColor = () => {
    var r = randomInt(0, 255);
    var g = randomInt(0, 255);
    var b = randomInt(0, 255);
    return `rgba(${r}, ${g}, ${b}, 0.2)`;
  };
  const generateOneDataSet = (label, startDate, endDate, data, timeInterval) => {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const dateCollection = {};
    const stringFormat = timeInterval === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM';
    while (startMoment.isBefore(endMoment) || startMoment.isSame(endMoment)) {
      dateCollection[startMoment.format(stringFormat)] = 0;
      startMoment.add(1, timeInterval);
    }
    const groupedData = data.reduce((acc, val) => {
      const dateString = moment(val).format(stringFormat);
      if (acc[dateString] >= 0) {
        acc[dateString] += 1;
      }
      return acc;
    }, dateCollection);
    const formattedData = Object.keys(groupedData).reduce((acc, val) => {
      return [...acc, {
        t: val,
        y: groupedData[val]
      }];
    }, []);
    const dataColor = randomColor();
    return {
      label,
      data: formattedData,
      backgroundColor: dataColor,
      borderColor: dataColor,
      borderWidth: 1
    };
  };
  function generatePercentage(selected, all) {
    if (selected < 1) {
      return '';
    }
    return `${(selected / all * 100).toFixed(2)}%`;
  }

  // generates table data for graphs with two datasets, e.g. total orders and a subset of selected orders
  function transformFinalDataToSelectedDataTableData(data) {
    const allDataArrIndex = data.findIndex(dataset => {
      return dataset.label.includes('All');
    });
    const allDataArr = data[allDataArrIndex].data;
    const selectedDataArrIndex = allDataArrIndex === 1 ? 0 : 1;
    const selectedDataArr = data[selectedDataArrIndex].data;
    const sums = {
      selected: 0,
      all: 0
    };
    const tableData = allDataArr.map((line, i) => {
      const all = line.y;
      const selected = selectedDataArr[i].y;
      sums.selected += selected;
      sums.all += all;
      return {
        period: line.t,
        all: all,
        selected: selected,
        percentage: generatePercentage(selected, all)
      };
    });
    tableData.push({
      period: 'total',
      all: sums.all,
      selected: sums.selected,
      percentage: generatePercentage(sums.selected, sums.all)
    });
    return tableData;
  }

  // generates table data for a graph with a single dataset, e.g. number of SKUs sold
  function transformFinalDataToSingleTableData(data) {
    const allDataArr = data[0].data;
    let sum = 0;
    const tableData = allDataArr.map((line, i) => {
      const all = line.y;
      sum += all;
      return {
        period: line.t,
        all: all
      };
    });
    tableData.push({
      period: 'total',
      all: sum
    });
    return tableData;
  }

  /*
   * This transformer takes an array of timestamps
   * and outputs an array of days with counts, like:
   * [{t: "2020-03-01", y: 23}, {t: "2020-03-02", y: 33}]
   */
  function _default(label, startDate, endDate, data, timeInterval) {
    const finalData = Array.isArray(data) ? [generateOneDataSet(label, startDate, endDate, data, timeInterval)] : Object.keys(data).map(key => {
      return generateOneDataSet(data[key].label, startDate, endDate, data[key].data, timeInterval);
    });
    const tableData = finalData.length > 1 ? transformFinalDataToSelectedDataTableData(finalData) : transformFinalDataToSingleTableData(finalData);
    return {
      type: 'line',
      data: {
        datasets: finalData
      },
      tableData: tableData,
      options: {
        scales: {
          xAxes: [{
            ticks: {
              source: 'auto',
              autoSkip: true,
              maxTicksLimit: 40
            },
            offset: true,
            type: 'time',
            distribution: 'series'
          }]
        }
      }
    };
  }
});