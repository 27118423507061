define("admin/lib/graphs/index", ["exports", "admin/lib/graphs/time-series-counter", "admin/lib/graphs/time-series", "admin/lib/graphs/bar-graph"], function (_exports, _timeSeriesCounter, _timeSeries, _barGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    timeSeriesCounter: _timeSeriesCounter.default,
    timeSeries: _timeSeries.default,
    barGraph: _barGraph.default
  };
  _exports.default = _default;
});