define("admin/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = Model.extend({
    eventType: attr('string'),
    type: 'event',
    meta: attr()
  });
  _exports.default = _default;
});