define("admin/routes/utils/delete-user-data", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    model(params) {
      if (params && params.search) {
        return this._fetchAndStoreSearchRecords(params.search);
      }
      return [];
    },
    actions: {
      performSearch() {
        const searchTerm = Ember.get(this, 'controller.search');
        if (!searchTerm || !searchTerm.length) return;
        Ember.set(this, 'controller.loading', true);
        return this._fetchAndStoreSearchRecords(searchTerm).then(results => Ember.set(this, 'controller.model', results)).finally(() => Ember.set(this, 'controller.loading', false));
      },
      delete(userId, email) {
        let message = `Are you sure you would like to delete the user account associated with '${email}'? This action cannot be undone.`;
        if (confirm(message) == true) {
          const ajaxService = Ember.get(this, 'ajax');
          if (!ajaxService) return;
          return ajaxService.request(`/users/${userId}`, {
            method: 'DELETE'
          }).then(() => {
            this.transitionTo('users.show', userId);
          }).catch(err => {
            let failedStep = err.errors[0].detail;
            Ember.get(this, 'notify').alert(`Something went wrong when attempting to delete the user data associated with '${email}'. The '${failedStep}' operation could not be completed. Please pass the email and failed operation to a developer to take a closer look!`);
          });
        }
      }
    },
    _fetchAndStoreSearchRecords(searchTerm) {
      const ajaxService = Ember.get(this, 'ajax');
      if (!ajaxService) return;
      return ajaxService.request(`/users/search?email=${searchTerm}`, {
        method: 'GET'
      }).then(res => {
        if (!res.data || !res.data.length) return;
        const storeService = Ember.get(this, 'store');
        storeService.pushPayload(res);
        return res.data.map(entry => storeService.peekRecord(entry.type, entry.id));
      }).catch(() => {
        Ember.get(this, 'notify').alert(`Something went wrong when attempting to search for '${searchTerm}'. Please pass the search query to a developer to take a closer look!`);
      });
    }
  });
  _exports.default = _default;
});