define("admin/transforms/moment", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    deserialize(serialized) {
      if (serialized) {
        return _moment.default.utc(serialized);
      }
    },
    serialize(deserialized) {
      if (deserialized) {
        return deserialized.toISOString();
      }
    }
  });
  _exports.default = _default;
});