define("admin/templates/apks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bFaOBR1i",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"Apks center-column px1 pt2 md:px0\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"center-column my2\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"h1\",true],[10],[1,1,0,0,\"LightOS Apks\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[\"lightos\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[31,150,9,[27,[26,1,\"CallHead\"],[]],null,[[\"data\"],[[27,[26,0,\"Expression\"],[\"lightos\"]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"     \"],[9,\"h1\",true],[10],[1,1,0,0,\"Sideload Apks\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[\"sideload\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[31,266,9,[27,[26,1,\"CallHead\"],[]],null,[[\"data\"],[[27,[26,0,\"Expression\"],[\"sideload\"]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[9,\"ul\",true],[10],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[9,\"span\",true],[12,\"style\",\"color:green\",null],[10],[1,1,0,0,\"green\"],[11],[1,1,0,0,\" - available to all\"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[9,\"span\",true],[12,\"style\",\"color:yellow\",null],[10],[1,1,0,0,\"yellow\"],[11],[1,1,0,0,\" - available to some\"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[9,\"span\",true],[12,\"style\",\"color:blue\",null],[10],[1,1,0,0,\"blue\"],[11],[1,1,0,0,\" - available to admins (beta)\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"apk-table\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/apks.hbs"
    }
  });
  _exports.default = _default;
});