define("admin/templates/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "d/mKqI/o",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"SignIn center-column px1 md:px0\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"sign-in-form\"]}",
    "meta": {
      "moduleName": "admin/templates/sign-in.hbs"
    }
  });
  _exports.default = _default;
});