define("admin/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XR/2+dc4",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,29,12,[27,[26,3,\"CallHead\"],[]],null,[[\"closeAfter\"],[4000]]]]],\"hasEval\":false,\"upvars\":[\"app-header\",\"-outlet\",\"component\",\"ember-notify\"]}",
    "meta": {
      "moduleName": "admin/templates/application.hbs"
    }
  });
  _exports.default = _default;
});