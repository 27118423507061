define("admin/models/followed-podcast", ["exports", "ember-data/model", "ember-data/relationships", "ember-data/attr"], function (_exports, _model, _relationships, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    deviceTool: (0, _relationships.belongsTo)('device-tool', {
      async: false
    }),
    podcastId: (0, _attr.default)('string'),
    podcast: (0, _relationships.belongsTo)('podcast', {
      async: false
    }),
    type: 'followed-podcast'
  });
  _exports.default = _default;
});