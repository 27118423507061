define("admin/templates/devices/show/tools/podcasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "II8KsMhO",
    "block": "{\"symbols\":[\"followedPodcast\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"DevicesShow center-column px1 pt2 md:px0\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"h3\",true],[12,\"class\",\"eyebrow mb_5 py_25\",null],[10],[1,1,0,0,\"Followed Podcasts\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,156,13,[27,[26,0,\"CallHead\"],[]],null,[[\"resource\"],[[27,[24,1],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[1,1,0,0,\"    There are no followed podcasts associated with this device\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"resource-card\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/devices/show/tools/podcasts.hbs"
    }
  });
  _exports.default = _default;
});