define("admin/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  var _default = Model.extend({
    givenName: attr('string'),
    familyName: attr('string'),
    email: attr('string'),
    roles: attr(),
    devices: hasMany('device'),
    isAdmin: Ember.computed('roles', function () {
      return this.roles.includes('admin');
    }),
    type: 'user'
  });
  _exports.default = _default;
});