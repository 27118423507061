define("admin/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ApplicationSerializer extends _emberData.default.JSONAPISerializer {
    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }
    keyForRelationship(attr) {
      return Ember.String.underscore(attr);
    }
    normalizeQueryResponse(_store, _class, payload) {
      const result = super.normalizeQueryResponse(...arguments);
      result.meta = result.meta || {};
      if (payload.links) {
        result.meta.pagination = this.createPaginationMeta(payload.links);
      }
      return result;
    }
    createPaginationMeta(data) {
      // https://emberigniter.com/pagination-in-ember-with-json-api-backend/
      // since we need to convert the JSON API spec'ed pagination
      // to be accessible for Ember Data 
      let meta = {};
      Object.keys(data).forEach(type => {
        const url = data[type];
        const [, number, size] = url.split('&page');
        const [, numberVal] = number.split('=');
        const [, sizeVal] = size.split('=');
        meta[type] = {
          number: numberVal,
          size: sizeVal
        };
      });
      return meta;
    }
  }
  _exports.default = ApplicationSerializer;
});