define("admin/controllers/utils/graphs/snapshot-timeline", ["exports", "moment", "admin/lib/graphs"], function (_exports, moment, _graphs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    snapshotFields: ['device_memory_available_mb', 'eink_busy_time_average_ms', 'runtime_memory_available_mb', 'device_storage_available_mb'],
    selectedSnapshotField: 'device_memory_available_mb',
    startDate: new Date(),
    endDate: new Date(),
    validationError: null,
    actions: {
      handleStartDateChange(arr) {
        Ember.set(this, 'startDate', arr[0]);
      },
      handleEndDateChange(arr) {
        Ember.set(this, 'endDate', arr[0]);
      },
      submit() {
        Ember.set(this, 'validationError', null);
        const startDate = Ember.get(this, 'startDate');
        const endDate = Ember.get(this, 'endDate');
        const areDatesValid = moment(startDate).isSame(moment(endDate)) || moment(startDate).isBefore(moment(endDate));
        if (!areDatesValid) {
          Ember.set(this, 'validationError', 'End date cannot be before start date.');
          return;
        }
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
        const fieldName = Ember.get(this, 'selectedSnapshotField');
        const ajaxService = Ember.get(this, 'ajax');
        Ember.set(this, 'loading', true);
        return ajaxService.request(`/analytics/graph_data/rollups?start_date=${formattedStartDate}&end_date=${formattedEndDate}`).then(res => {
          const converted = res.data.map(item => {
            return {
              date: item.attributes.date,
              [fieldName]: item.attributes[fieldName]
            };
          });
          const chartData = _graphs.default.timeSeries(fieldName, converted, fieldName);
          Ember.set(this, 'loading', false);
          Ember.set(this, 'model', chartData);
        }).catch(err => {
          Ember.set(this, 'validationError', `Unexpected error: ${err.message}`);
          Ember.set(this, 'loading', false);
        });
      }
    }
  });
  _exports.default = _default;
});