define("admin/routes/devices/show/events/index", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    pageSize: 50,
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    model(params) {
      const device = this.modelFor('devices.show');
      const pageSize = Ember.get(this, 'pageSize');
      return this.store.query('event', {
        device_id: device.id,
        page: {
          page: params.page,
          page_size: pageSize
        }
      }).then(function (result) {
        const self = Ember.get(result, 'meta.pagination.self.number');
        const prev = Ember.get(result, 'meta.pagination.prev.number');
        const next = Ember.get(result, 'meta.pagination.next.number');
        const last = Ember.get(result, 'meta.pagination.last.number') || self;
        const isFirst = self === 1;
        const isLast = self === last;
        return {
          self,
          prev,
          next,
          last,
          isFirst,
          isLast,
          events: result
        };
      });
    }
  });
  _exports.default = _default;
});