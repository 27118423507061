define("admin/controllers/utils/graphs/orders", ["exports", "admin/mixins/controller-time-series-graph"], function (_exports, _controllerTimeSeriesGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_controllerTimeSeriesGraph.default, {
    offset: false,
    phone: false,
    accessory: false,
    plan: false,
    sim: false,
    modifiers: Ember.computed('offset', 'phone', 'accessory', 'plan', 'sim', function () {
      const modifiers = {
        modifierName: 'categories',
        categories: []
      };
      if (Ember.get(this, 'offset')) {
        modifiers.categories.push('offset');
      }
      if (Ember.get(this, 'phone')) {
        modifiers.categories.push('phone');
      }
      if (Ember.get(this, 'accessory')) {
        modifiers.categories.push('accessory');
      }
      if (Ember.get(this, 'plan')) {
        modifiers.categories.push('plan');
      }
      if (Ember.get(this, 'sim')) {
        modifiers.categories.push('sim');
      }
      return modifiers.categories.length > 0 ? modifiers : null;
    }),
    actions: {
      handleOffsetChange() {
        Ember.set(this, 'offset', !Ember.get(this, 'offset'));
      },
      handlePhoneChange() {
        Ember.set(this, 'phone', !Ember.get(this, 'phone'));
      },
      handleAccessoryChange() {
        Ember.set(this, 'accessory', !Ember.get(this, 'accessory'));
      },
      handlePlanChange() {
        Ember.set(this, 'plan', !Ember.get(this, 'plan'));
      },
      handleSimChange() {
        Ember.set(this, 'sim', !Ember.get(this, 'sim'));
      }
    }
  });
  _exports.default = _default;
});