define("admin/lib/graphs/time-series", ["exports", "moment"], function (_exports, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /*
   * This transformer takes an array values per day e.g. [{active_users: 2513, date: "2020-03-01T00:00:00"}]
   * and produces the graph format like:
   * [{t: "2020-03-01", y: 23}, {t: "2020-03-02", y: 33}]
   */
  function _default(label, data, fieldName) {
    const stringFormat = 'YYYY-MM-DD';
    const finalData = data.map(d => {
      return {
        t: moment(d.date).format(stringFormat),
        y: d[fieldName]
      };
    });
    return {
      type: 'line',
      data: {
        datasets: [{
          label,
          data: finalData,
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
          borderColor: ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              source: 'auto',
              autoSkip: true,
              maxTicksLimit: 40
            },
            offset: true,
            type: 'time',
            distribution: 'series'
          }]
        }
      }
    };
  }
});