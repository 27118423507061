define("admin/routes/devices/show/tools/tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model({
      device_tool_id
    }) {
      const deviceTool = Ember.get(this.modelFor('devices.show'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'id') === device_tool_id;
      });
      const deviceNamespace = deviceTool.tool.namespace;
      switch (deviceNamespace) {
        case 'podcasts':
          this.transitionTo('devices.show.tools.podcasts');
          break;
        default:
          break;
      }
      return deviceTool;
    }
  });
  _exports.default = _default;
});