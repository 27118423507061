define("admin/controllers/utils/download-recently-active", ["exports", "moment"], function (_exports, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    admin: Ember.inject.service(),
    date: new Date(),
    error: null,
    loading: false,
    actions: {
      handleDateChange(arr) {
        Ember.set(this, 'date', arr[0]);
      },
      downloadRecentlyActiveUsers() {
        if (Ember.get(this, 'loading')) return;
        Ember.set(this, 'error', false);
        Ember.set(this, 'loading', true);
        const date = Ember.get(this, 'date');
        const formattedDate = moment(date).format('YYYY-MM-DD');
        return Ember.get(this, 'admin').getRecentlyActiveUsers(formattedDate).then(recentlyActiveUsers => {
          const usersCsvString = recentlyActiveUsers.data.reduce((acc, user) => {
            const {
              id
            } = user;
            const {
              email,
              family_name,
              given_name,
              phone_contact
            } = user.attributes;
            return acc.concat(`\n${id},${email},${family_name},${given_name},${phone_contact}`);
          }, 'data:text/csv;charset=utf-8,id,email,family_name,given_name,phone_contact');
          const encodedUri = encodeURI(usersCsvString);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `users-active-since-${date}.csv`);
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
          Ember.set(this, 'error', `Error: ${err.message}`);
        }).finally(() => {
          Ember.set(this, 'loading', false);
        });
      }
    }
  });
  _exports.default = _default;
});