define("admin/routes/utils/update-light-site-content", ["exports", "admin/routes/authenticated", "admin/config/environment"], function (_exports, _authenticated, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    notify: Ember.inject.service(),
    actions: {
      syncStaging() {
        this._dispatchWebhook("staging").then(res => {
          if (res.ok) return Ember.get(this, 'notify').success("Sent request to trigger a content update on staging!");
          Ember.get(this, 'notify').alert(`There was an issue dispatching this request. Status: ${res.status}`);
        });
      },
      syncProduction() {
        this._dispatchWebhook("production").then(res => {
          if (res.ok) return Ember.get(this, 'notify').success("Sent request to trigger a content update on production!");
          Ember.get(this, 'notify').alert(`There was an issue dispatching this request. Status: ${res.status}`);
        });
      }
    },
    _dispatchWebhook(environment) {
      return fetch("https://api.github.com/repos/sanctuarycomputer/light-site/dispatches", {
        method: "POST",
        body: JSON.stringify({
          "event_type": `webhook-${environment}`,
          "client_payload": {
            "action": `deploy-${environment}`
          }
        }),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${_environment.default.GIT.token}`
        }
      });
    }
  });
  _exports.default = _default;
});