define("admin/routes/sign-in", ["exports", "ember-ajax/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    admin: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      signIn(email, password) {
        const adminService = Ember.get(this, 'admin');
        if (!adminService) return;
        const notifyService = Ember.get(this, 'notify');
        return adminService.signIn(email, password).then(() => this._transitionAfterSignin()).catch(error => {
          if ((0, _errors.isForbiddenError)(error)) {
            return notifyService.alert('Something went wrong. Please check your email/password.');
          }
          return notifyService.alert('Something went wrong. Please try again.');
        });
      },
      logout() {
        const adminService = Ember.get(this, 'admin');
        if (!adminService) return;
        return adminService.logOut().then(() => this._transitionAfterLogout());
      }
    },
    // Privado
    _transitionAfterSignin() {
      this.transitionTo('search');
    },
    _transitionAfterLogout() {
      this.transitionTo('sign-in');
    }
  });
  _exports.default = _default;
});