define("admin/components/sign-in-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    admin: Ember.inject.service(),
    classNames: ['SignIn bg-color-off-white mt4'],
    email: '',
    password: '',
    actions: {
      updateAttr(type, value) {
        Ember.set(this, type, value);
      },
      submit() {
        const email = this.get('email');
        const password = this.get('password');
        if (!email.length || !password.length) return;
        return this.admin.signIn(email, password);
      }
    }
  });
  _exports.default = _default;
});