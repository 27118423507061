define("admin/templates/utils/update-light-site-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0SyOEohD",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"mb2\",null],[10],[1,1,0,0,\"Manually Update Light Site Content\"],[11],[1,1,0,0,\"\\n\"],[9,\"div\",true],[12,\"class\",\"center-column px1 md:px0\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[10],[1,1,0,0,\"\\n    \"],[9,\"button\",false],[23,\"class\",\"LogOut button-reset body pointer mt1\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],[31,170,12,[27,[26,0,\"CallHead\"],[]],[\"syncStaging\"],null]],null],[10],[1,1,0,0,\"Sync Staging\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[10],[1,1,0,0,\"\\n    \"],[9,\"button\",false],[23,\"class\",\"LogOut button-reset body pointer mt1\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],[31,307,12,[27,[26,0,\"CallHead\"],[]],[\"syncProduction\"],null]],null],[10],[1,1,0,0,\"Sync Production\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"route-action\",\"action\"]}",
    "meta": {
      "moduleName": "admin/templates/utils/update-light-site-content.hbs"
    }
  });
  _exports.default = _default;
});