define("admin/services/ajax", ["exports", "ember-ajax/services/ajax", "admin/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    admin: Ember.inject.service(),
    cookies: Ember.inject.service(),
    host: _environment.default.API.baseUrl,
    namespace: 'api',
    headers: Ember.computed('admin.currentToken', function () {
      const headers = {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      };
      const authToken = Ember.get(this, 'admin.currentToken');
      if (authToken !== null) {
        Object.assign(headers, {
          authorization: `Bearer ${authToken}`
        });
      }
      return headers;
    })
  });
  _exports.default = _default;
});