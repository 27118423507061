define("admin/routes/fotas", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    model() {
      return this.store.findAll('fota').then(fotas => fotas.sortBy('version').reverse());
    }
  });
  _exports.default = _default;
});