define("admin/routes/apks", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    model() {
      return this.store.findAll('apk').then(apks => {
        const lightos = apks.filter(({
          name
        }) => name === 'com.lightos');
        const sideload = apks.filter(({
          name
        }) => name !== 'com.lightos');
        return Ember.RSVP.hash({
          lightos: lightos.sort(apk => apk.version).reverse(),
          sideload: sideload.sort(apk => apk.version).reverse()
        });
      });
    }
  });
  _exports.default = _default;
});