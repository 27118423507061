define("admin/routes/application", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    admin: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel() {
      const adminService = Ember.get(this, 'admin');
      if (!adminService) return;
      if (adminService.cookies.exists('token')) {
        const token = adminService.cookies.read('token');
        return Ember.set(adminService, 'currentToken', token);
      }
    },
    model() {
      const adminService = Ember.get(this, 'admin');
      if (!adminService || !adminService.currentToken) return;
      return adminService.current().catch(error => {
        adminService.handleUnauthorizedErrors(error);
      });
    },
    afterModel(model) {
      if (!model) return;
      const adminService = Ember.get(this, 'admin');
      const currentUser = adminService.loadCurrentUser(model);
      if (currentUser && currentUser.isAdmin) {
        return;
      }
      window.location = _environment.default.API.dashRedirectUrl;
      return;
    },
    actions: {
      logOut() {
        const adminService = Ember.get(this, 'admin');
        if (!adminService) return;
        return adminService.logOut().then(() => {
          return this.transitionTo('sign-in');
        });
      }
    }
  });
  _exports.default = _default;
});