define("admin/controllers/utils/order-confirmation-email-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    admin: Ember.inject.service(),
    saving: false,
    _transformAttrsForPersistence(attrs) {
      const affectedSkus = Ember.get(attrs, 'settings.affected_skus');
      const regex = /[^,\s]+/g;
      let dAffectedSkus;
      if (affectedSkus && affectedSkus.length > 0) {
        dAffectedSkus = affectedSkus.match(regex);
      } else {
        dAffectedSkus = [];
      }
      Ember.set(attrs, 'settings.affected_skus', dAffectedSkus);
      return attrs;
    },
    actions: {
      persistOrderConfirmationEmailSettings(attrs) {
        const confirmed = confirm(`WARNING!\n\nThese changes will go into effect immediately.\nAre you sure you want to commit them?`);
        if (confirmed) {
          Ember.set(this, 'saving', true);
          const transformed = this._transformAttrsForPersistence(attrs);
          const data = {
            data: {
              attributes: transformed,
              type: "order_confirmation_email_settings"
            }
          };
          const ajaxService = Ember.get(this, 'ajax');
          return ajaxService.put('/system_settings/order_confirmation_email_settings', {
            data: data
          }).then(() => {
            this.send('saveSucceeded');
          }).catch(() => {
            this.send('saveFailed');
          }).finally(() => {
            Ember.set(this, 'saving', false);
          });
        }
      }
    }
  });
  _exports.default = _default;
});