define("admin/models/device-metrics-snapshot", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  var _default = Model.extend({
    event: attr('string'),
    snapshot: attr(),
    insertedAt: attr('moment'),
    updatedAt: attr('moment'),
    type: 'device-metrics-snapshot'
  });
  _exports.default = _default;
});