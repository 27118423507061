define("admin/router", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('sign-in');
    this.route('search', {
      path: '/'
    });
    this.route('fotas', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('apks', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('users', function () {
      this.route('show', {
        path: '/:user_id'
      });
    });
    this.route('devices', function () {
      this.route('show', {
        path: '/:device_id'
      }, function () {
        this.route('metrics-snapshots', function () {
          this.route('index', {
            path: '/'
          });
        });
        this.route('events', function () {
          this.route('index', {
            path: '/'
          });
        });
        this.route('tools', function () {
          this.route('podcasts', {
            path: '/podcasts'
          });
          this.route('tool', {
            path: '/:device_tool_id'
          });
        });
      });
    });
    this.route('utils', function () {
      this.route('order-confirmation');
      this.route('download-recently-active');
      this.route('graphs', function () {
        this.route('orders');
        this.route('active-users');
        this.route('active-devices');
        this.route('joined-users');
        this.route('skus-sold');
        this.route('inserted-devices');
        this.route('snapshot-breakdown');
        this.route('snapshot-timeline');
      });
      this.route('order-confirmation-email-settings');
      this.route('delete-user-data');
      this.route('update-light-site-content');
    });
  });
  var _default = Router;
  _exports.default = _default;
});