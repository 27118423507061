define("admin/adapters/application", ["exports", "ember-data", "admin/config/environment", "ember-inflector"], function (_exports, _emberData, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPIAdapter.extend({
    admin: Ember.inject.service(),
    host: _environment.default.API.baseUrl,
    namespace: 'api',
    headers: Ember.computed('admin.currentToken', function () {
      const authToken = Ember.get(this, 'admin.currentToken');
      if (authToken !== null) {
        return {
          authorization: `Bearer ${authToken}`
        };
      }
    }),
    pathForType(type) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(type));
    }
  });
  _exports.default = _default;
});