define("admin/components/app-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    admin: Ember.inject.service(),
    classNames: ['bg-color-off-white p1 md:px2']
  });
  _exports.default = _default;
});