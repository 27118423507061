define("admin/controllers/utils/graphs/snapshot-breakdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    snapshotFields: ['light_os_version_code', 'firmware_version', 'colors_inverted', 'screen_off_timeout', 'message_notification', 'haptic_feedback_enabled', 'light_web_authenticated', 'date_displayed_on_standby', 'brightness_automatic_enabled', 'average_usage_frequency_breakdown'],
    selectedSnapshotField: 'light_os_version_code'
  });
  _exports.default = _default;
});