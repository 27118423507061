define("admin/routes/devices/show/tools/podcasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      const deviceTool = Ember.get(this.modelFor('devices.show.tools'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'tool.namespace') === 'podcasts';
      });
      return Ember.get(this, 'store').query('followed-podcast', {
        device_tool_id: Ember.get(deviceTool, 'id')
      }).then(followedPodcasts => {
        return followedPodcasts.sortBy('podcast.title');
      });
    }
  });
  _exports.default = _default;
});