define("admin/templates/devices/show/tools/tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b7Jz7Tjo",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"DevicesShow center-column px1 pt2 md:px0\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,59,13,[27,[26,1,\"CallHead\"],[]],null,[[\"resource\"],[[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"resource-card\"]}",
    "meta": {
      "moduleName": "admin/templates/devices/show/tools/tool.hbs"
    }
  });
  _exports.default = _default;
});