define("admin/routes/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    admin: Ember.inject.service(),
    beforeModel() {
      const currentUser = Ember.get(this, 'admin.currentUser');
      if (!currentUser || !currentUser.isAdmin) {
        this.transitionTo('sign-in');
      }
    }
  });
  _exports.default = _default;
});