define("admin/templates/fotas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N6WGdZlu",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"Fotas center-column px1 pt2 md:px0\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"center-column my2\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"h1\",true],[10],[1,1,0,0,\"Firmware Updates\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[31,147,10,[27,[26,1,\"CallHead\"],[]],null,[[\"data\"],[[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"      \"],[9,\"p\",true],[10],[1,1,0,0,\"No available fotas\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"ul\",true],[10],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[9,\"span\",true],[12,\"style\",\"color:green\",null],[10],[1,1,0,0,\"green\"],[11],[1,1,0,0,\" - available to all\"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[9,\"span\",true],[12,\"style\",\"color:yellow\",null],[10],[1,1,0,0,\"yellow\"],[11],[1,1,0,0,\" - available to some\"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[9,\"span\",true],[12,\"style\",\"color:blue\",null],[10],[1,1,0,0,\"blue\"],[11],[1,1,0,0,\" - available to admins (beta)\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"fota-table\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/fotas.hbs"
    }
  });
  _exports.default = _default;
});