define("admin/components/fota-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      updateFirmwareAvailability: function (fota, event) {
        fota.set('available_to', parseInt(event.target.value));
      },
      updateEnforced: function (fota, event) {
        const value = event.target.value;
        fota.set('enforced', value === 'true');
      },
      submit: function (fota) {
        fota.save();
      }
    }
  });
  _exports.default = _default;
});