define("admin/mixins/controller-time-series-graph", ["exports", "moment", "admin/lib/graphs"], function (_exports, moment, _graphs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    startDate: new Date(),
    endDate: new Date(),
    timeIntervals: ['day', 'month'],
    selectedTimeInterval: 'day',
    validationError: null,
    actions: {
      handleStartDateChange(arr) {
        Ember.set(this, 'startDate', arr[0]);
      },
      handleEndDateChange(arr) {
        Ember.set(this, 'endDate', arr[0]);
      },
      submit(type, label, count, fieldName, modifiers) {
        Ember.set(this, 'validationError', null);
        const selectedTimeInterval = Ember.get(this, 'selectedTimeInterval');
        const startDate = Ember.get(this, 'startDate');
        const endDate = Ember.get(this, 'endDate');
        const areDatesValid = moment(startDate).isSame(moment(endDate)) || moment(startDate).isBefore(moment(endDate));
        if (!areDatesValid) {
          Ember.set(this, 'validationError', 'End date cannot be before start date.');
          return;
        }
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
        const ajaxService = Ember.get(this, 'ajax');
        Ember.set(this, 'loading', true);
        const requestModifier = modifiers ? `&${modifiers.modifierName}=${modifiers[modifiers.modifierName].join(',')}` : '';
        const requestUrl = `/analytics/graph_data/${type}?start_date=${formattedStartDate}&end_date=${formattedEndDate}${requestModifier}`;
        return ajaxService.request(requestUrl).then(res => {
          const {
            dataset
          } = res.data.attributes;
          const dataToTransform = Array.isArray(dataset) ? dataset : {
            allData: {
              data: dataset.all_data,
              label: `All ${label}`
            },
            modifiedData: {
              data: dataset.data_with_modifiers,
              label: `Selected ${label}`
            }
          };
          const chartData = count ? _graphs.default.timeSeriesCounter(label, formattedStartDate, formattedEndDate, dataToTransform, selectedTimeInterval) : _graphs.default.timeSeries(label, dataset, fieldName);
          Ember.set(this, 'loading', false);
          Ember.set(this, 'model', chartData);
        }).catch(err => {
          Ember.set(this, 'validationError', `Unexpected error: ${err.message}`);
          Ember.set(this, 'loading', false);
        });
      }
    }
  });
  _exports.default = _default;
});