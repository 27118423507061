define("admin/lib/graphs/bar-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const randomColor = () => {
    var h = randomInt(0, 360);
    var s = randomInt(42, 98);
    var l = randomInt(40, 90);
    return `hsl(${h},${s}%,${l}%)`;
  };
  function _default(label, data) {
    const labels = Object.keys(data);
    const colors = [];
    for (let index = 0; index < labels.length; index++) {
      colors.push(randomColor());
    }
    return {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          data: Object.values(data),
          backgroundColor: colors
        }]
      },
      options: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: label
        }
      }
    };
  }
});