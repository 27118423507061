define("admin/models/device", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = Model.extend({
    imei: attr('string'),
    iccid: attr('string'),
    sku: attr('string'),
    serialNumber: attr('string'),
    phoneNumber: attr('string'),
    softwareVersion: attr('string'),
    firmwareVersion: attr('string'),
    nextcloudUsername: attr('string'),
    nextcloudPassword: attr('string'),
    nextcloudEndpoint: attr('string'),
    admin: attr('boolean'),
    user: belongsTo('user'),
    sim: belongsTo('sim'),
    deviceTools: hasMany('device-tool'),
    latestMetricsSnapshot: belongsTo('device-metrics-snapshot', {
      async: false,
      inverse: null
    }),
    metricsSnapshots: hasMany('device-metrics-snapshot', {
      async: true
    }),
    uploadedContacts: hasMany('uploaded-contact', {
      async: true
    }),
    latestEvent: belongsTo('event', {
      async: false,
      inverse: null
    }),
    event: hasMany('event', {
      async: true
    }),
    type: 'device'
  });
  _exports.default = _default;
});