define("admin/routes/utils/graphs/snapshot-timeline", ["exports", "admin/routes/authenticated", "admin/lib/graphs"], function (_exports, _authenticated, _graphs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    admin: Ember.inject.service(),
    notify: Ember.inject.service(),
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'value', '');
      Ember.set(controller, 'loading', false);
      Ember.set(controller, 'error', false);
    },
    model() {},
    actions: {
      submit() {
        if (Ember.get(this, 'controller.loading')) return;
        Ember.set(this, 'controller.error', null);
        Ember.set(this, 'controller.loading', true);
        const fieldName = Ember.get(this, 'controller.selectedSnapshotField');
        return Ember.get(this, 'admin').getDeviceSnapshotBreakdown(fieldName).then(resp => {
          const dataset = resp.data.attributes.dataset;
          const chartData = _graphs.default.barGraph(`${fieldName} Breakdown`, dataset);
          Ember.set(this, 'controller.model', chartData);
          Ember.set(this, 'controller.dataset', Object.entries(dataset));
        }).catch(err => {
          Ember.set(this, 'controller.error', `Error: ${err.message}`);
        }).finally(() => {
          Ember.set(this, 'controller.loading', false);
        });
      }
    }
  });
  _exports.default = _default;
});