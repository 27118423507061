define("admin/services/admin", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    cookies: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    currentUser: null,
    currentToken: null,
    hasCurrentUser: Ember.computed('currentUser', function () {
      return Ember.get(this, 'currentUser.isAdmin');
    }),
    sendOrderConfirmation(number) {
      return Ember.get(this, 'ajax').request('/orders/confirmation', {
        method: 'POST',
        data: {
          data: {
            attributes: {
              order_number: number
            }
          }
        }
      });
    },
    getRecentlyActiveUsers(date) {
      return Ember.get(this, 'ajax').request(`/users?sort=active_since&date=${date}`);
    },
    getDeviceSnapshotTimeline(startDate, endDate) {
      return Ember.get(this, 'ajax').request(`/analytics/graph_data/rollups?start_date=${startDate}&end_date=${endDate}`);
    },
    getDeviceSnapshotBreakdown(field) {
      return Ember.get(this, 'ajax').request(`/analytics/graph_data/snapshot_breakdown?field=${field}`);
    },
    signIn(email, password) {
      const ajaxService = Ember.get(this, 'ajax');
      if (!ajaxService) return;
      return ajaxService.request('/authorizations', {
        method: 'POST',
        data: {
          email: email,
          password: password
        }
      }).then(res => {
        this._authorizeAdminUser(res);
      });
    },
    signInAs(user_id) {
      const ajaxService = Ember.get(this, 'ajax');
      if (!ajaxService) return;
      return ajaxService.request('/support_tokens', {
        method: 'POST',
        data: {
          user_id
        }
      }).then(res => {
        return this._handleSupportTokenResponse(res);
      });
    },
    logOut() {
      const ajaxService = Ember.get(this, 'ajax');
      if (!ajaxService) return;
      return ajaxService.request('/tokens/revoke', {
        method: 'DELETE'
      }).then(() => this._clearCurrentUserToken());
    },
    current() {
      const ajaxService = Ember.get(this, 'ajax');
      if (!ajaxService) return;
      return ajaxService.request('/users/current', {
        method: 'GET'
      });
    },
    handleUnauthorizedErrors(error) {
      if (Ember.get(error, 'status') === 401) {
        return this._clearTokensAndRedirect();
      } else {
        throw error;
      }
    },
    loadCurrentUser(userData) {
      const userId = Ember.get(userData, 'data.id');
      if (!userId) return false;
      const storeService = Ember.get(this, 'store');
      storeService.pushPayload(userData);
      const currentUser = storeService.peekRecord('user', userId);
      return Ember.set(this, 'currentUser', currentUser);
    },
    updateDevice(deviceId, data) {
      const ajaxService = Ember.get(this, 'ajax');
      return ajaxService.request('/devices/' + deviceId, {
        method: 'PUT',
        data
      });
    },
    pingDevice(deviceId) {
      const ajaxService = Ember.get(this, 'ajax');
      const notifyService = Ember.get(this, 'notify');
      if (!ajaxService || !notifyService) return;
      const data = {
        data: {
          type: 'device_ping',
          attributes: {
            device_id: deviceId
          }
        }
      };
      return ajaxService.request('/devices/ping', {
        method: 'POST',
        data
      }).then(res => {
        const roundTripMs = Ember.get(res, 'data.attributes.round_trip_ms');
        if (roundTripMs) {
          return Promise.resolve(roundTripMs);
        } else {
          return Promise.reject('Failed to get ping round trip.');
        }
      });
    },
    // Privado
    _clearTokensAndRedirect() {
      const routerService = Ember.get(this, 'router');
      this._clearCurrentUserToken();
      return routerService.transitionTo('sign-in');
    },
    _authorizeAdminUser(authData) {
      const storeService = Ember.get(this, 'store');
      const userData = Ember.get(authData, 'data');
      const tokenData = Ember.get(authData, 'included').length ? Ember.get(authData, 'included')[0] : null;
      if (!Ember.get(userData, 'id') || !tokenData || !Ember.get(tokenData, 'attributes.token')) return;
      this.loadCurrentUser(authData);
      if (Ember.get(this, 'currentUser.isAdmin')) {
        return this._storeCurrentUserToken(tokenData);
      }
      storeService.unloadAll();
      Ember.set(this, 'currentUser', null);
      throw new Error('Not authorized');
    },
    _handleSupportTokenResponse(tokenReponse) {
      const supportToken = Ember.get(tokenReponse, 'data.attributes.token');
      const supportTokenMaxAge = Ember.get(tokenReponse, 'data.attributes.max_age');
      if (!supportToken) return;
      return this._storeSupportToken(supportToken, supportTokenMaxAge);
    },
    _storeCurrentUserToken(tokenData) {
      const token = Ember.get(tokenData, 'attributes.token');
      const maxAge = Ember.get(tokenData, 'attributes.max_age');
      const cookiesService = Ember.get(this, 'cookies');
      if (!cookiesService) return;
      Ember.set(this, 'currentToken', token);
      cookiesService.write('token', token, {
        maxAge: maxAge,
        domain: _environment.default.API.domain,
        path: '/'
      });
    },
    _clearCurrentUserToken() {
      const cookiesService = Ember.get(this, 'cookies');
      if (!cookiesService) return;
      cookiesService.clear('token');
      Ember.set(this, 'currentToken', null);
      return Ember.set(this, 'currentUser', null);
    },
    _storeSupportToken(token, maxAge) {
      const cookiesService = Ember.get(this, 'cookies');
      if (!cookiesService) return;
      Ember.set(this, 'currentSupportToken', token);
      return cookiesService.write('support_token', token, {
        maxAge: maxAge,
        domain: _environment.default.API.domain,
        path: '/'
      });
    },
    _clearSupportToken() {
      const cookiesService = Ember.get(this, 'cookies');
      if (!cookiesService) return;
      cookiesService.clear('support_token');
      return Ember.set(this, 'currentSupportToken', null);
    }
  });
  _exports.default = _default;
});