define("admin/routes/search", ["exports", "admin/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticated.default.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    model(params) {
      if (params && params.search) {
        return this._fetchAndStoreSearchRecords(params.search);
      }
      return [];
    },
    actions: {
      performSearch() {
        const searchQuery = Ember.get(this, 'controller.search');
        if (!searchQuery || !searchQuery.length) return;
        Ember.set(this, 'controller.loading', true);
        return this._fetchAndStoreSearchRecords(searchQuery).then(results => Ember.set(this, 'controller.model', results)).finally(() => Ember.set(this, 'controller.loading', false));
      }
    },
    _fetchAndStoreSearchRecords(searchQuery) {
      const serializedQuery = this._serializeQuery(searchQuery);
      const ajaxService = Ember.get(this, 'ajax');
      if (!ajaxService) return;
      return ajaxService.request(`/search?${serializedQuery}`, {
        method: 'GET'
      }).then(res => {
        if (!res.data || !res.data.length) return;
        const storeService = Ember.get(this, 'store');
        storeService.pushPayload(res);
        return res.data.map(entry => storeService.peekRecord(entry.type, entry.id));
      }).catch(err => {
        Ember.get(this, 'notify').alert('Something went wrong, and the search results could not be returned. Please pass the search query to a developer to take a closer look!');
      });
    },
    _serializeQuery(query) {
      if (!query || !query.length) return;
      const splits = query.split(' ');
      const type = splits.find(item => item.includes('type'));
      const attributes = splits.filter(item => item !== type);
      return this._buildQueryString(type, attributes);
    },
    _buildQueryString(type, attrs) {
      if (!type || !type.length) return;
      if (!attrs || !attrs.length) return type;
      return attrs.reduce((queryString, attr, index, attrsArray) => {
        const isLastAttr = index === attrsArray.length - 1;
        return isLastAttr ? `${queryString}${attr}` : `${queryString}${attr}&`;
      }, `${type}&`);
    }
  });
  _exports.default = _default;
});