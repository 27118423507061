define("admin/models/uploaded-contact", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    device: (0, _relationships.belongsTo)('device', {
      async: false
    }),
    complete: (0, _attr.default)('string'),
    history: (0, _attr.default)(),
    type: (0, _attr.default)('string'),
    inserted_at: (0, _attr.default)('string'),
    updated_at: (0, _attr.default)('string'),
    began_at: (0, _attr.default)('string'),
    retry_count: (0, _attr.default)('number'),
    nextcloud_account_id: (0, _attr.default)('string')
  });
  _exports.default = _default;
});