define("admin/controllers/utils/graphs/skus-sold", ["exports", "admin/mixins/controller-time-series-graph"], function (_exports, _controllerTimeSeriesGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_controllerTimeSeriesGraph.default, {
    selectedProduct: null,
    modifiers: Ember.computed('selectedProduct', function () {
      const modifiers = {
        modifierName: 'sku',
        sku: [Ember.get(this, 'selectedProduct')]
      };
      return modifiers;
    }),
    actions: {
      handleProductChange(product) {
        console.log('changing product to ' + product);
        Ember.set(this, 'selectedProduct', product);
      }
    }
  });
  _exports.default = _default;
});