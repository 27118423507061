define("admin/templates/utils/download-recently-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EMR2YhgN",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"mb2\",null],[10],[1,1,0,0,\"Download recently active users\"],[11],[1,1,0,0,\"\\n\"],[9,\"p\",true],[12,\"class\",\"mb2\",null],[10],[1,1,0,0,\"\\n  Input date in below format. You may need to disable your adblocker.\\n\"],[11],[1,1,0,0,\"\\n\"],[9,\"form\",false],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],[31,159,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"downloadRecentlyActiveUsers\"],null]],[[\"on\"],[\"submit\"]]],[10],[1,1,0,0,\"\\n  \"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,236,15,[27,[26,4,\"CallHead\"],[]],null,[[\"date\",\"onChange\"],[[31,258,8,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null],[31,283,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"handleDateChange\"],null]]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,343,5,[27,[26,5,\"CallHead\"],[]],null,[[\"class\",\"type\",\"value\"],[\"input-radius body px1 pointer\",\"submit\",\"Generate csv\"]]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"p\",true],[12,\"class\",\"my2\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[12,\"class\",\"validation-error\",null],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"error\",\"action\",\"date\",\"readonly\",\"ember-flatpickr\",\"input\",\"if\"]}",
    "meta": {
      "moduleName": "admin/templates/utils/download-recently-active.hbs"
    }
  });
  _exports.default = _default;
});