define("admin/routes/devices/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    admin: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      toggleAdmin(admin) {
        const device = this.modelFor('devices.show');
        const adminService = Ember.get(this, 'admin');
        const notifyService = Ember.get(this, 'notify');
        if (!device || !adminService) return;
        const data = {
          data: {
            id: device.id,
            type: 'device',
            attributes: {
              admin
            }
          }
        };
        return adminService.updateDevice(device.id, data).then(() => {
          notifyService.success('Successfully updated device admin status');
        }).catch(() => {
          notifyService.alert('Something went wrong while trying to update admin status');
        });
      },
      pingDevice() {
        const device = this.modelFor('devices.show');
        const adminService = Ember.get(this, 'admin');
        const notifyService = Ember.get(this, 'notify');
        if (!device || !adminService) return;
        return adminService.pingDevice(Ember.get(device, 'id')).then(roundTripMs => {
          notifyService.success(`Success! The Device responded within ${roundTripMs} milliseconds.`);
        }).catch(error => {
          const errors = Ember.get(error, 'payload.errors');
          if (errors && errors[0]) {
            const detail = Ember.get(errors[0], 'detail');
            if (detail) {
              notifyService.alert(detail);
              return;
            }
          }
          notifyService.alert('Something went wrong.');
        });
      }
    }
  });
  _exports.default = _default;
});