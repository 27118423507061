define("admin/components/resource-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    actions: {
      async downloadVcf(resource) {
        const id = Ember.get(resource, 'id');
        const ajaxService = Ember.get(this, 'ajax');
        const {
          url
        } = await ajaxService.request(`/uploaded_contacts?import_id=${id}`);
        const fetched = await fetch(url);
        const blob = await fetched.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `contact-upload-${id}.vcf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
  });
  _exports.default = _default;
});