define("admin/templates/components/orders-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "45Dqg5C5",
    "block": "{\"symbols\":[\"line\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"flex items-center my2\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"table\",true],[12,\"class\",\"graphing-table\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"thead\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"th\",true],[10],[1,1,0,0,\"\\n        Date Period\\n      \"],[11],[1,1,0,0,\"\\n      \"],[9,\"th\",true],[10],[1,1,0,0,\"\\n        Selected Order Count\\n      \"],[11],[1,1,0,0,\"\\n      \"],[9,\"th\",true],[10],[1,1,0,0,\"\\n        Total Order Count\\n      \"],[11],[1,1,0,0,\"\\n      \"],[9,\"th\",true],[10],[1,1,0,0,\"\\n        Percentage\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"tbody\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n          \"],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"period\"]]],[11],[1,1,0,0,\"\\n          \"],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"selected\"]]],[11],[1,1,0,0,\"\\n          \"],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"all\"]]],[11],[1,1,0,0,\"\\n          \"],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"percentage\"]]],[11],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"data\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "admin/templates/components/orders-table.hbs"
    }
  });
  _exports.default = _default;
});